import { io } from "socket.io-client";
import { apiConfig } from "../../../config/api";
import * as Sentry from "@sentry/react";
import { logger } from "../../../logger";

export const signalingChannel = io(apiConfig.url, {
  path: "/api/v1/ws",
});

signalingChannel.on("connect", () => {
  logger.info("Socket connected")
  Sentry.setTag("peer_id", signalingChannel.id)
});

signalingChannel.on("disconnect", () => {
  logger.info("Socket disconnected")
  window.location.reload();
})
