import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  createStyles,
  Divider,
  makeStyles,
  ThemeProvider,
  Toolbar,
  Typography,
  Link
} from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LeaveIcon from "@material-ui/icons/CallEnd";

import { NettuLogoWithLabel } from "../shared/components/NettuLogoWithLabel";
import { Timer } from "../shared/components/Timer";
import { ChatBtn } from "../modules/chat/components/ChatBtn";
import { PeersVideoLayout } from "../modules/media/components/PeersVideoLayout";
import { meetingState } from "../modules/meeting/state/meeting";
import { meetingInteractor } from "../modules/meeting/interactors";
import { ChatDrawer } from "../modules/chat/components/ChatDrawer";
import { chatInteractor } from "../modules/chat/interactors/chatInteractor";
import { leaveRoom } from "../modules/media/state/utils";
import { useLayoutState } from "../shared/services/layout/layout";
import { darkTheme } from "../shared/services/theme/darkTheme";
import { lightTheme } from "../shared/services/theme/lightTheme";
import { useLocalStreams } from "../modules/media/state/state";
import { DeviceSelectPopover } from "../shared/components/DeviceSelectPopover";
import { storage } from "../shared/infra/Storage";
import { mediaManager } from "../modules/media/services/MediaManager";

interface Props extends RouteComponentProps<{ meetingId: string }> { }

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    appbar: {
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        "0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2)",
    },
    body: {
      position: "relative",
    },
    videoSidebarContainer: {
      position: "fixed",
      right: 0,
      top: 0,
      bottom: 0,
      width: "400px",
      boxShadow: theme.shadows[2],
    },
    videoSidebar: {},
    meetingModeBtn: {
      height: "40px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      backgroundColor: "#29303b",
      fontSize: "1rem",
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#51575f",
      },
    },
  })
);

const styles = {
  mediaIcon: {
    fontSize: "1.2rem",
    color: "#3f51b5",
  },
  mediaIconActive: {
    color: "#fff",
  },
};


const MeetingRoom = (props: Props) => {
  const classes = useStyles();
  const user = storage.getUserInfo()!

  const [s, setS] = useState(Math.random());

  const [devicesAnchorEl, setDevicesAnchorEl] = useState<any>(null);

  const {
    config,
    muteAudio,
    unmuteAudio,
    muteScreen,
    unmuteScreen,
    muteWebcam,
    unmuteWebcam,
  } = useLocalStreams();
  const { meeting } = meetingState();
  const { videoMode, toggleVideoMode } = useLayoutState();

  const displayUpperToolbar = () => (
    <Toolbar variant="dense">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <NettuLogoWithLabel label={meeting ? meeting.title : ""} />
        <ButtonGroup disableElevation color="primary">
          {/* {user.role == "teacher" && (
            <Button
              variant={config.webcam ? "outlined" : "contained"}
              onClick={() => (config.webcam ? muteWebcam() : unmuteWebcam())}
            >
              {config.webcam ? (
                <VideoCamOffIcon style={styles.mediaIcon} />
              ) : (
                <VideoCamIcon
                  style={{ ...styles.mediaIcon, ...styles.mediaIconActive }}
                />
              )}
            </Button>
          )} */}

          {/* {user.role == "teacher" && (
            <Button
              variant={config.audio ? "outlined" : "contained"}
              onClick={() => (config.audio ? muteAudio() : unmuteAudio())}
            >
              {config.audio ? (
                <MicOffIcon style={styles.mediaIcon} />
              ) : (
                <MicIcon
                  style={{ ...styles.mediaIcon, ...styles.mediaIconActive }}
                />
              )}
            </Button>
          )} */}

          {/* {user.role == "teacher" && (
            <Button
              variant={config.screen ? "outlined" : "contained"}
              onClick={() => (config.screen ? muteScreen() : unmuteScreen())}
            >
              {config.screen ? (
                <StopScreenShareIcon style={styles.mediaIcon} />
              ) : (
                <ScreenShareIcon
                  style={{ ...styles.mediaIcon, ...styles.mediaIconActive }}
                />
              )}
            </Button>
          )} */}

          {/* {user.role == "teacher" && (
            <Button
              variant="contained"
              onClick={(e) => setDevicesAnchorEl(e.currentTarget)}
            >
              <SettingsIcon />
            </Button>
          )} */}

          <DeviceSelectPopover
            anchorEl={devicesAnchorEl}
            open={Boolean(devicesAnchorEl)}
            onClose={() => setDevicesAnchorEl(undefined)}
          />
        </ButtonGroup>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <div style={{ width: "30px" }}></div>
          <Timer />
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            style={{ marginLeft: "25px" }}
            onClick={async () => {
              if (!meeting) return;
              await leaveRoom();
              mediaManager.leave();
              window.location.reload();
            }}
            startIcon={<LeaveIcon />}
          >
            Leave
          </Button>
          {(user.role == "teacher" && meeting && meeting.status != 'opening') && (
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ marginLeft: "25px" }}
              onClick={() => (meetingInteractor.openMeetingRoom(meeting.id))}
            >
              Start class
            </Button>
          )}
          {(user.role == "teacher" && meeting && meeting.status == 'opening') && (
            <Button
              variant="contained"
              size="small"
              color="inherit"
              style={{ marginLeft: "25px" }}
              onClick={() => (meetingInteractor.closeMeetingRoom(meeting.id))}
            >
              End class
            </Button>
          )}
          <Button
            color="secondary"
            variant="contained"
            size="small"
            style={{ marginLeft: "25px" }}
            onClick={() => {window.location.href='https://elearning.mmavens.com/MM_LIVE'}}
          >
            GO TO PORTAL
          </Button>
        </Box>
      </Box>
    </Toolbar>
  );

  const displayTopPadding = () => (
    <Fragment>
      <Toolbar variant="dense" />
      <div style={{ height: "22px" }}></div>
      {videoMode ? null : (
        <Fragment>
          <Divider />
          <Toolbar variant="dense" />
        </Fragment>
      )}
    </Fragment>
  );

  useEffect(() => {
    window.onresize = () => setS(Math.random());
  }, []);

  const displayVideoModusBody = () => (
    <ThemeProvider theme={darkTheme}>
      <PeersVideoLayout
        sidebarMode={false}
        size={{
          width: window.innerWidth,
          height: window.innerHeight - 70,
        }}
      />
    </ThemeProvider>
  );

  const theme = videoMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.container}>
        <AppBar
          position="fixed"
          className={classes.appbar}
          style={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {displayUpperToolbar()}
          <div style={{ height: "22px" }}>
            <Link
              target="_blank"
              style={{
                marginLeft: "25px",
                fontSize: "1.1rem",
                color: "yellow"
              }}
              href='https://zoom.us/?fbclid=IwAR2V-jPjFJE7yI-jkp30T1Gp9nCsvsW8tQ7GUw1Tfg8ys8yZQEIEo8l8Tuw'
            >
              Go to zoom
            </Link>
          </div>
        </AppBar>
        <div className={classes.body}>
          {displayTopPadding()}
          {displayVideoModusBody()}
        </div>
        <ChatDrawer chatInteractor={chatInteractor} />
      </div>
    </ThemeProvider>
  );
};

export default withRouter(MeetingRoom);
