import { StreamSettings } from "../state/state";

export const VIDEO_BOTTOMS_HEIGHT = 120;
export const VIDEO_BOTTOMS_MAX_WIDTH = (VIDEO_BOTTOMS_HEIGHT * 4) / 3;

interface PeerStream<T> {
  isMe: boolean;
  role: string;
  isMain: boolean;
  isSpeaking: boolean;
  speakingCount: number;
  settings?: StreamSettings;
  data: T;
}

export interface GetLayoutRequest<T> {
  isTeacher?: boolean;
  sidebarMode: boolean;
  size: {
    width: number;
    height: number;
  };
  peerStreams: PeerStream<T>[];
}

interface PeerLayout<T> {
  isMe: boolean;
  role: string;
  layout: {
    size: {
      width: number;
      height: number;
    };
  };
  _priority: number;
  data: T;
}

export interface GetLayoutResponse<T> {
  mainVideos: PeerLayout<T>[];
  bottomLeftVideos: PeerLayout<T>[];
  bottomRightVideos: PeerLayout<T>[];
  isPresentingMode: boolean;
}

/**
 * POSSIBLE IMPROVEMENTS
 * 1. me video placed more intelligentely
 * 2. priority to users who have video or audio enabled or is talking
 */

export function getVideoLayout<T>(
  req: GetLayoutRequest<T>
): GetLayoutResponse<T> {
  const mainPeerStreams = req.peerStreams.filter((p) => p.isMain)
  const mainPeerDisplays: PeerStream<T>[] = []
  if (mainPeerStreams.length > 9){
    const mainPeerStreamsSharing = mainPeerStreams.filter(p => (p.settings && p.settings.screen))
    const mainPeerStreamsNotSharing = mainPeerStreams.filter(p => !(p.settings && p.settings.screen))
    if (mainPeerStreamsSharing.length > 9) {
      mainPeerDisplays.push(...mainPeerStreamsSharing.slice(0, 9))
    } else if (mainPeerStreamsSharing.length == 9) {
      mainPeerDisplays.push(...mainPeerStreamsSharing)
    } else {
      mainPeerDisplays.push(...mainPeerStreamsSharing)
      mainPeerDisplays.push(...mainPeerStreamsNotSharing.slice(0, 9-mainPeerDisplays.length))
    }
  }
  else {
    mainPeerDisplays.push(...mainPeerStreams)
  }

  const bottomPeerDisplays = req.peerStreams.filter((p) => !mainPeerDisplays.includes(p))

  const res: GetLayoutResponse<T> = {
    mainVideos: [],
    bottomLeftVideos: [],
    bottomRightVideos: [],
    isPresentingMode: mainPeerDisplays.length > 0,
  };

  const peersCount = req.peerStreams.length;
    let peersPerRow = peersCount >= 7 ? 3 : peersCount > 1 ? 2 : 1;
    let peersPerColumn = peersCount >= 5 ? 3 : peersCount > 2 ? 2 : 1;
    if (req.sidebarMode && peersCount === 2) {
      peersPerColumn = 2;
      peersPerRow = 1;
    }
  const mainVideosHeight = req.isTeacher || bottomPeerDisplays.length > 0 ? req.size.height - VIDEO_BOTTOMS_HEIGHT : req.size.height;

  if (mainPeerDisplays.length == 1){
    const pc = mainPeerDisplays[0]
    res.mainVideos.push({
      layout: {
        size: {
          width: req.size.width,
          height:
            req.size.height -
            (req.peerStreams.length > 1 ? VIDEO_BOTTOMS_HEIGHT : 0),
        },
      },
      isMe: Boolean(pc.isMe),
      role: pc.role,
      data: pc.data,
      _priority: getPeerPriority(pc),
    })
  } else {
    for (const pc of mainPeerDisplays){
      res.mainVideos.push({
        layout: {
          size: {
            width: req.size.width / peersPerRow,
            height: mainVideosHeight / peersPerColumn,
          },
        },
        isMe: Boolean(pc.isMe),
        role: pc.role,
        data: pc.data,
        _priority: getPeerPriority(pc),
      });
    }
  }

  if (bottomPeerDisplays.length > 0){
    const bottomLeftPeerDisplays = bottomPeerDisplays.slice(0, Math.ceil(bottomPeerDisplays.length/2))
    const bottomRightPeerDisplays = bottomPeerDisplays.slice(Math.ceil(bottomPeerDisplays.length/2), bottomPeerDisplays.length)

    for (const pc of bottomLeftPeerDisplays){
      res.bottomLeftVideos.push({
        layout: {
          size: {
            width: Math.min(
              req.size.width / Math.max(1, req.peerStreams.length - 9),
              VIDEO_BOTTOMS_MAX_WIDTH
            ),
            height: VIDEO_BOTTOMS_HEIGHT,
          },
        },
        isMe: Boolean(pc.isMe),
        role: pc.role,
        data: pc.data,
        _priority: getPeerPriority(pc),
      });
    }

    for (const pc of bottomRightPeerDisplays){
      res.bottomRightVideos.push({
        layout: {
          size: {
            width: Math.min(
              req.size.width / Math.max(1, req.peerStreams.length - 9),
              VIDEO_BOTTOMS_MAX_WIDTH
            ),
            height: VIDEO_BOTTOMS_HEIGHT,
          },
        },
        isMe: Boolean(pc.isMe),
        role: pc.role,
        data: pc.data,
        _priority: getPeerPriority(pc),
      });
    }
  }

  // if (mainPeerStreams.length > 0){
  //   for (const pc of req.peerStreams) {
  //     if (pc.isMain) {
  //       res.mainVideos.push({
  //         layout: {
  //           size: {
  //             width: req.size.width,
  //             height:
  //               req.size.height -
  //               (req.peerStreams.length > 1 ? VIDEO_BOTTOMS_HEIGHT : 0),
  //           },
  //         },
  //         isMe: Boolean(pc.isMe),
  //         role: pc.role,
  //         data: pc.data,
  //         _priority: getPeerPriority(pc),
  //       });
  //     } else {
  //       res.bottomVideos.push({
  //         layout: {
  //           size: {
  //             width: Math.min(
  //               req.size.width / Math.max(1, req.peerStreams.length - 1),
  //               VIDEO_BOTTOMS_MAX_WIDTH
  //             ),
  //             height: VIDEO_BOTTOMS_HEIGHT,
  //           },
  //         },
  //         isMe: Boolean(pc.isMe),
  //         role: pc.role,
  //         data: pc.data,
  //         _priority: getPeerPriority(pc),
  //       });
  //     }
  //   }
  // }
  // else if (someoneIsSharingScreen) {
  //   for (const pc of req.peerStreams) {
  //     const settings = pc.settings;
  //     const sharingScreen = settings && settings.screen;
  //     if (sharingScreen && res.mainVideos.length === 0) {
  //       res.mainVideos.push({
  //         layout: {
  //           size: {
  //             width: req.size.width,
  //             height:
  //               req.size.height -
  //               (req.peerStreams.length > 1 ? VIDEO_BOTTOMS_HEIGHT : 0),
  //           },
  //         },
  //         isMe: Boolean(pc.isMe),
  //         role: pc.role,
  //         data: pc.data,
  //         _priority: getPeerPriority(pc),
  //       });
  //     } else {
  //       res.bottomVideos.push({
  //         layout: {
  //           size: {
  //             width: Math.min(
  //               req.size.width / Math.max(1, req.peerStreams.length - 1),
  //               VIDEO_BOTTOMS_MAX_WIDTH
  //             ),
  //             height: VIDEO_BOTTOMS_HEIGHT,
  //           },
  //         },
  //         isMe: Boolean(pc.isMe),
  //         role: pc.role,
  //         data: pc.data,
  //         _priority: getPeerPriority(pc),
  //       });
  //     }
  //   }
  // } else {
  //   const peersCount = req.peerStreams.length;
  //   let counter = 0;
  //   let peersPerRow = peersCount >= 7 ? 3 : peersCount > 1 ? 2 : 1;
  //   let peersPerColumn = peersCount >= 5 ? 3 : peersCount > 2 ? 2 : 1;
  //   if (req.sidebarMode && peersCount === 2) {
  //     peersPerColumn = 2;
  //     peersPerRow = 1;
  //   }
  //   const mainVideosHeight =
  //     peersCount > 9 ? req.size.height - VIDEO_BOTTOMS_HEIGHT : req.size.height;

  //   for (const pc of req.peerStreams) {
  //     if (counter < 9) {
  //       res.mainVideos.push({
  //         layout: {
  //           size: {
  //             width: req.size.width / peersPerRow,
  //             height: mainVideosHeight / peersPerColumn,
  //           },
  //         },
  //         isMe: Boolean(pc.isMe),
  //         role: pc.role,
  //         data: pc.data,
  //         _priority: getPeerPriority(pc),
  //       });
  //     } else {
  //       res.bottomVideos.push({
  //         layout: {
  //           size: {
  //             width: Math.min(
  //               req.size.width / Math.max(1, req.peerStreams.length - 9),
  //               VIDEO_BOTTOMS_MAX_WIDTH
  //             ),
  //             height: VIDEO_BOTTOMS_HEIGHT,
  //           },
  //         },
  //         isMe: Boolean(pc.isMe),
  //         role: pc.role,
  //         data: pc.data,
  //         _priority: getPeerPriority(pc),
  //       });
  //     }
  //     counter += 1;
  //   }
  // }

  // res.mainVideos.sort((v1, v2) => v2._priority - v1._priority);
  // res.bottomVideos.sort((v1, v2) => v2._priority - v1._priority);

  return res;
}

export const getPeerPriority = (req: PeerStream<any>): number => {
  let priority = 0;
  if (req.isSpeaking) {
    priority += 25;
  }
  priority += Math.min(req.speakingCount, 7);
  if (req.settings && req.settings.webcam) {
    priority += 15;
  }
  if (req.settings && req.settings.audio) {
    priority += 10;
  }

  return priority;
};
