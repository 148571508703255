import { IconButton, makeStyles } from "@material-ui/core";
import { Loop } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import { Consumer } from "mediasoup-client/lib/Consumer";
import { useEffect, useState } from "react";
import { storage } from "../../../shared/infra/Storage";
import { signalingChannel } from "../../../shared/services/theme/signalling";
import {
  GetLayoutResponse,
  getVideoLayout,
  VIDEO_BOTTOMS_HEIGHT,
} from "../domain/layoutLogic";
import { useLocalStreams, useActivePeerConsumers } from "../state/state";
import { PeerVideo } from "./PeerVideo";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#202124",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
  },
  mainVideos: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "hidden",
  },
  bottomVideos: {
    height: `${VIDEO_BOTTOMS_HEIGHT}px`,
    borderTop: `2px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
}));

interface Props {
  sidebarMode: boolean;
  size: {
    width: number;
    height: number;
  };
}

const TeacherPeersVideoLayout = (props: Props) => {
  const classes = useStyles();
  const activePeerConsumers = useActivePeerConsumers();

  const [main, setMain] = useState(null as null | string); // peerId
  const [page, setPage] = useState(1);
  const [mapRotation, setMapRotation] = useState({} as { [key: string]: number });
  // const [layout, setLayout] = useState({
  //   mainVideos: [],
  //   bottomVideos: [],
  //   isPresentingMode: false
  // } as GetLayoutResponse<any>);

  const itemPerPage = 9
  const countPage = Math.ceil(activePeerConsumers.length / itemPerPage);


  const currentActivePeerConsumers = activePeerConsumers.slice((page - 1) * itemPerPage, (page - 1) * itemPerPage + itemPerPage)
  const layout = getVideoLayout({
    isTeacher: true,
    peerStreams: [
      ...currentActivePeerConsumers.map((p) => ({
        isMe: false,
        role: p.role,
        isMain: main ? main == p.peerId : true,
        settings: {
          webcam: p.consumers.find((c) => c.track.kind === "video") != null,
          audio: p.consumers.find((c) => c.track.kind === "audio") != null,
          screen:
            p.consumers.find((c) => c.appData.mediaTag === "screen") != null,
        },
        isSpeaking: false,
        speakingCount: 0,
        data: {
          consumerIds: p.consumers.map((c) => c.id),
          stream: new MediaStream(p.consumers.map((c) => c.track.clone())),
          settings: {
            webcam: p.consumers.find((c) => c.track.kind === "video") != null,
            audio: p.consumers.find((c) => c.track.kind === "audio") != null,
            screen:
              p.consumers.find((c) => c.appData.mediaTag === "screen") != null,
          },
          isSpeaking: false,
          peerId: p.peerId,
          name: p.name,
        },
      }))
    ],
    sidebarMode: props.sidebarMode,
    size: props.size,
  });

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRotation = () => {
    const peerId = main;
    if (!peerId) return;
    const newMapRotation = { ...mapRotation }
    newMapRotation[peerId] = (newMapRotation[peerId] ? newMapRotation[peerId] : 0) + 90
    if (newMapRotation[peerId] == 360) newMapRotation[peerId] = 0
    setMapRotation(newMapRotation)
  }

  return (
    <div
      className={classes.container}
      style={{
        width: props.size.width + "px",
        height: props.size.height + "px",
      }}
    >
      <div className={classes.mainVideos} style={{ height: `${props.size.height - VIDEO_BOTTOMS_HEIGHT}px` }}>
        {layout.mainVideos.map((p) => (
          <div>
            <div
              key={p.data.peerId}
              style={{
                width: p.layout.size.width + "px",
                height: p.layout.size.height + "px",
              }}
              onClick={() => {
                main ? setMain(null) : setMain(p.data.peerId)
              }}
            >
              <PeerVideo
                stream={p.data.stream}
                streamSettings={p.data.settings}
                isSpeaking={p.data.isSpeaking}
                isMe={p.isMe}
                role={p.role}
                fullName={p.data.name}
                consumerIds={p.data.consumerIds}
                darkBackground={layout.isPresentingMode}
                border={!props.sidebarMode && layout.mainVideos.length > 1}
                size={p.layout.size}
                rotation={mapRotation[p.data.peerId] ? mapRotation[p.data.peerId] : 0}
              />
            </div>
          </div>
        ))}
      </div>


      <div className={classes.bottomVideos}>
        <div style={{
          width: "40%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
        }}>
          {layout.bottomLeftVideos.map((p) => (
            <div
              key={p.data.peerId}
              style={{
                width: p.layout.size.width + "px",
                height: p.layout.size.height + "px",
              }}
              onClick={() => setMain(p.data.peerId)}
            >
              <PeerVideo
                stream={p.data.stream}
                isSpeaking={p.data.isSpeaking}
                streamSettings={p.data.settings}
                consumerIds={p.data.consumerIds}
                isMe={p.isMe}
                fullName={p.data.name}
                size={p.layout.size}
                rotation={mapRotation[p.data.peerId] ? mapRotation[p.data.peerId] : 0}
              />
            </div>
          ))}
        </div>


        <div style={{

        }}>
          <div>
            {
              <IconButton
                style={{
                  margin: "auto",
                  display: "block"
                }}
                color="primary"
                onClick={handleChangeRotation}
              >
                <Loop style={{ height: "30px", width: "30px" }}></Loop>
              </IconButton>
            }

            <Pagination
              style={{
                margin: "auto",
                display: "block"
              }}
              count={countPage}
              color="primary"
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChange}
            />
          </div>
        </div>
        <div style={{
          width: "40%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}>
          {layout.bottomRightVideos.map((p) => (
            <div
              key={p.data.peerId}
              style={{
                width: p.layout.size.width + "px",
                height: p.layout.size.height + "px",
              }}
              onClick={() => setMain(p.data.peerId)}
            >
              <PeerVideo
                stream={p.data.stream}
                isSpeaking={p.data.isSpeaking}
                streamSettings={p.data.settings}
                consumerIds={p.data.consumerIds}
                isMe={p.isMe}
                fullName={p.data.name}
                size={p.layout.size}
                rotation={mapRotation[p.data.peerId] ? mapRotation[p.data.peerId] : 0}
              />
            </div>
          ))}
        </div>

      </div>
    </div>


  );
};

const StudentPeersVideoLayout = (props: Props) => {
  const classes = useStyles();
  // const activePeerConsumers = useActivePeerConsumers();

  const { config, combinedStream } = useLocalStreams();
  const [mySpeakStats, setMySpeakStats] = useState({
    isSpeaking: false,
    speakingCount: 0,
  });

  const [main, setMain] = useState(null as null | string); // peerId
  // const activePeerConsumersFilted = activePeerConsumers.filter(x => x.role == 'teacher')

  const layout = getVideoLayout({
    peerStreams: [
      // ...activePeerConsumersFilted.map((p) => ({
      //   isMe: false,
      //   role: p.role,
      //   isMain: main ? main == p.peerId : true,
      //   settings: {
      //     webcam: p.consumers.find((c) => c.track.kind === "video") != null,
      //     audio: p.consumers.find((c) => c.track.kind === "audio") != null,
      //     screen:
      //       p.consumers.find((c) => c.appData.mediaTag === "screen") != null,
      //   },
      //   isSpeaking: false,
      //   speakingCount: 0,
      //   data: {
      //     consumerIds: p.consumers.map((c) => c.id),
      //     stream: new MediaStream(p.consumers.map((c) => c.track.clone())),
      //     settings: {
      //       webcam: p.consumers.find((c) => c.track.kind === "video") != null,
      //       audio: p.consumers.find((c) => c.track.kind === "audio") != null,
      //       screen:
      //         p.consumers.find((c) => c.appData.mediaTag === "screen") != null,
      //     },
      //     isSpeaking: false,
      //     peerId: p.peerId,
      //     name: p.name,
      //   },
      // })),
      {
        isMe: true,
        role: 'student',
        // isMain: main ? main == signalingChannel.id : activePeerConsumersFilted.length > 0 ? false : true,
        isMain: true,
        settings: config,
        isSpeaking: mySpeakStats.isSpeaking,
        speakingCount: mySpeakStats.speakingCount,
        data: {
          consumerIds: combinedStream.getTracks().map((t) => t.id),
          stream: combinedStream,
          settings: config,
          isSpeaking: mySpeakStats.isSpeaking,
          peerId: signalingChannel.id,
          name: "",
        },
      },
    ],
    sidebarMode: props.sidebarMode,
    size: props.size,
  });

  return (
    <div
      className={classes.container}
      style={{
        width: props.size.width + "px",
        height: props.size.height + "px",
      }}
    >
      {layout.mainVideos.map((p) => (
        <div
          key={p.data.peerId}
          style={{
            width: p.layout.size.width + "px",
            height: p.layout.size.height + "px",
          }}
        // onClick={() => {
        //   main ? setMain(null) : setMain(p.data.peerId)
        // }}
        >
          <PeerVideo
            stream={p.data.stream}
            streamSettings={p.data.settings}
            isSpeaking={p.data.isSpeaking}
            isMe={p.isMe}
            role={p.role}
            fullName={p.data.name}
            consumerIds={p.data.consumerIds}
            darkBackground={layout.isPresentingMode}
            border={!props.sidebarMode && layout.mainVideos.length > 1}
            size={p.layout.size}
          />
        </div>
      ))}


      {/* {layout.bottomVideos.length > 0 && (
        <div className={classes.bottomVideos}>
          {layout.bottomVideos.map((p) => (
            <div
              key={p.data.peerId}
              style={{
                width: p.layout.size.width + "px",
                height: p.layout.size.height + "px",
              }}
              onClick={() => setMain(p.data.peerId)}
            >
              <PeerVideo
                stream={p.data.stream}
                isSpeaking={p.data.isSpeaking}
                streamSettings={p.data.settings}
                consumerIds={p.data.consumerIds}
                isMe={p.isMe}
                fullName={p.data.name}
                size={p.layout.size}
              />
            </div>
          ))}
        </div>
      )} */}


    </div>
  );
};



export const PeersVideoLayout = (props: Props) => {
  const user = storage.getUserInfo()!
  if (user.role == 'student') return StudentPeersVideoLayout(props)
  return TeacherPeersVideoLayout(props)
};
