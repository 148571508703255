//
// our "signaling" function -- just an http fetch

import { apiConfig } from "../../../config/api";
import { signalingChannel } from "../../../shared/services/theme/signalling";
import { useRoomStore } from "./state";
import { logger } from "../../../logger"
import { storage } from "../../../shared/infra/Storage";
//
export const sig = async (endpoint: string, data: any) => {
    if (!data.roomId) {
        const roomId = useRoomStore.getState().roomId;
        data.roomId = roomId;
    }
    try {
        const headers: any = {
            "Content-Type": "application/json"
        }
        const token = storage.getToken()
        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }
        const body = JSON.stringify({ ...data, peerId: signalingChannel.id });

        const response = await fetch(
            apiConfig.baseUrl + '/signaling/' + endpoint, { method: 'POST', body, headers }
        );
        if (response.status >= 400){
            const data = await response.json();
            return { error: data.message }
        }
        return await response.json();
    } catch (e) {
        logger.error({ error: e }, e);
        return { error: e };
    }
}