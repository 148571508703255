import { Meeting } from "../domain/meeting";
import {
  IMeetingService,
} from "../services/meetingService";
import { updateMeetingState } from "../state/meeting";
import { logger } from "../../../logger";

export interface IMeetingInteractor {
  getMeetingById(meetingId: string): Promise<Meeting | undefined>;
  moveToMeetingLobby(): void;
  moveToMeetingRoom(): void;
  openMeetingRoom(meetingId: string): Promise<void>;
  closeMeetingRoom(meetingId: string): Promise<void>;
}

export class MeetingInteractor implements IMeetingInteractor {
  private meetingService: IMeetingService;

  constructor(meetingService: IMeetingService) {
    this.meetingService = meetingService;
  }

  async getMeetingById(meetingId: string) {
    let meeting: undefined | Meeting;
    try {
      meeting = await this.meetingService.getMeetingById(meetingId);
    } catch (error) {}
    logger.info({meeting : meeting}, "meeting got");
    updateMeetingState((s) => ({
      ...s,
      isLoadingMeeting: false,
      meeting,
    }));

    return meeting;
  }

  moveToMeetingLobby() {
    updateMeetingState((s) => ({
      ...s,
      inLoadingPage: false,
      inLobbyPage: true,
    }));
  }

  moveToMeetingRoom() {
    updateMeetingState((s) => ({
      ...s,
      inLoadingPage: false,
      inLobbyPage: false,
    }));
  }

  async openMeetingRoom(meetingId: string) {
    await this.meetingService.openMeetingById(meetingId);
    const meeting = await this.meetingService.getMeetingById(meetingId);
    updateMeetingState((s) => ({
      ...s,
      meeting
    }));
  }

  async closeMeetingRoom(meetingId: string) {
    await this.meetingService.closeMeetingById(meetingId);
    const meeting = await this.meetingService.getMeetingById(meetingId);
    updateMeetingState((s) => ({
      ...s,
      meeting
    }));
  }
}
