require('dotenv').config()
// const apiConfig = {
//   baseUrl: "https://e-learning.mmavens.com/api/v1",
//   url: "https://e-learning.mmavens.com",
//   docsUrl: "https://e-learning.mmavens.com/api/v1/docs",
// };

// const frontendUrl =  "https://e-learning.mmavens.com";

const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000'

const apiConfig = {
  baseUrl: `${apiUrl}/api/v1`,
  url: `${apiUrl}`,
  docsUrl: `${apiUrl}/api/v1/docs`,
};

const frontendUrl =  process.env.REACT_APP_BASE_URL || "http://localhost:3000";

export { apiConfig, frontendUrl };
