import { Result } from "../../../shared/core/Result";
import { BaseAPI } from "../../../shared/infra/services/BaseAPI";
import { Meeting } from "../domain/meeting";

interface MeetingEntrypoint {
  url: string;
}

interface CreatedDemoMeetingResponse {
  meetingId: string;
  entrypoint: MeetingEntrypoint;
}

export interface IMeetingService {
  getMeetingById(meetingId: string): Promise<Meeting | undefined>;
  createDemoMeeting(): Promise<Result<CreatedDemoMeetingResponse>>;
  openMeetingById(meetingId: string): Promise<Result<{}>>;
  closeMeetingById(meetingId: string): Promise<Result<{}>>;
}

export class MeetingService extends BaseAPI implements IMeetingService {
  async getMeetingById(meetingId: string): Promise<Meeting | undefined> {
    const res = await this.get(`/meeting/${meetingId}`);
    if (
      res.status !== 200 ||
      res.data == null ||
      res.data.meeting == null ||
      res.data.account == null
    ) {
      return;
    }
    const { meeting, account } = res.data;
    return res.status === 200 && res.data != null
      ? {
          ...meeting,
          account: account,
        }
      : undefined;
  }

  async createDemoMeeting(): Promise<Result<CreatedDemoMeetingResponse>> {
    const res = await this.post(`/meeting/demo`);
    if (res.status !== 201) {
      return Result.fail("Invalid response code");
    }
    return Result.ok(res.data);
  }

  async openMeetingById(meetingId: string): Promise<Result<{}>> {
    const res = await this.post(`/meeting/${meetingId}/open`);
    if (res.status !== 200) {
      return Result.fail("Invalid response code");
    }
    return Result.ok({});
  }

  async closeMeetingById(meetingId: string): Promise<Result<{}>> {
    const res = await this.post(`/meeting/${meetingId}/close`);
    if (res.status !== 200) {
      return Result.fail("Invalid response code");
    }
    return Result.ok({});
  }
}
