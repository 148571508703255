import { IUser } from "./models/User"

class Storage {
  constructor(){}

  getToken(){
    return localStorage.getItem('token')
  }

  setToken(token: string){
    return localStorage.setItem('token', token)
  }

  getUserInfo(){
    const text = localStorage.getItem('user')
    if (!text) return undefined
    return JSON.parse(text) as IUser
  }

  setUserInfo(user: IUser){
    localStorage.setItem('user', JSON.stringify(user))
  }
}

export const storage = new Storage()